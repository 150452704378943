<template>
  <div v-if="hasWhitelist" v-loading="getIsLoading" class="exporters-wrapper">
    <ControlTableWhitelistMobile
      v-if="isMobile"
      :table-data="tableData"
      :is-loading="getIsLoading"
      @editRow="handleEdit"
      @deleteRow="handleDelete"
      @selectDropdownMenu="handleTableMenu"
      @selectionChange="handleSelectionChange"
    />

    <div v-else class="control-table control-table-exporters">
      <div
        v-if="tableData.length || filteredPlateTruck"
        class="container container--full-screen"
      >
        <TableHeader
          :search="search"
          search-placeholder="Поиск по номеру авто"
          search-width="330px"
          @input="handleFilterInput"
          @clear="handleClearFilter"
          @search="handleSearchFilter"
        />
        <TableBody
          v-if="tableData.length"
          custom-class="exporters"
          :table-data="tableData"
          :table-fields-desktop="controlTableWhitelistFieldsDesktop"
          :table-dropdown-menu-list="controlTableWhitelistDropdownMenuList"
          :is-loading="getIsLoading"
          :has-icons="!isViewer && !isSupport"
          @editRow="handleEdit"
          @deleteRow="handleDelete"
          @selectDropdownMenu="handleTableMenu"
          @selectionChange="handleSelectionChange"
        >
          <template #default="slotProps">
            <TableCell
              :row="slotProps.data.row"
              :item="slotProps.data.row[slotProps.data.prop]"
              :prop-key="slotProps.data.prop"
              :status="slotProps.data.row.status"
              :width="slotProps.data.width"
              :min-width="slotProps.data.minWidth"
            />
          </template>
        </TableBody>

        <div v-else class="no-filtered">
          В списке нет автомобилей, соответствующих указанному фильтру
        </div>

        <TableFooter
          :pagination="pagination"
          text-quantity="Всего автомобилей:"
          item-names="автомобилей"
          @page="changePage"
          @size="changeSize"
        />
      </div>
      <TableEmpty
        v-if="!tableData.length && !filteredPlateTruck && !getIsLoading"
        warning="У вас не добавлено ни одного автомобиля в список транспорта терминала"
        proposition="Добавить авто в список"
        :has-upload="true"
        @add="addNewWhitelist"
        @upload="$refs.csvUpload.click()"
      />
    </div>

    <DialogEditWhitelist :params="params" />

    <DialogRemoveTableRow :params="params" />

    <input
      ref="csvUpload"
      type="file"
      name="file"
      accept=".csv"
      class="csv-upload"
      @change="fileSelected"
    />
  </div>
  <div v-else class="no-filtered">
    На данном терминале не подключен список транспорта терминала
  </div>
</template>

<script>
import {
  ADD_WHITELIST,
  FETCH_WHITELIST_PAGE,
  GET_IS_LOADING_WHITELIST,
  GET_WHITELIST_PAGE,
  GET_WHITELIST_PAGINATION,
} from '@/views/control/store/actions'
import {
  CONTROL_TABLE_WHITELIST_EDIT_ROW,
  CONTROL_TABLE_WHITELIST_REMOVE_ROW,
} from '@/constants/dialogs'
import {
  GET_HAS_WHITELIST,
  GET_IS_MOBILE,
  GET_TERMINAL_CURRENT_ID,
} from '@/store/actions'
import { TableBody, TableEmpty, TableFooter, TableHeader } from '@/UI/table'
import { checkCsvDate, getBlacklistTerm, numAutoValidator } from '@/core'
import {
  controlTableAddItem,
  controlTableRemoveSelectedItems,
  controlTableUpload,
} from '@/views/control/data/control'
import {
  controlTableWhitelistDropdownMenuList,
  controlTableWhitelistFieldsDesktop,
} from './data/ControlTableWhitelist'
import { mapActions, mapGetters } from 'vuex'
import { showMessageError } from '@/UI/message'
import ControlTableWhitelistMobile from './ControlTableWhitelistMobile'
import DialogEditWhitelist from './components/dialogs/dialog-edit-whitelist/DialogEditWhitelist'
import DialogRemoveTableRow from './components/dialogs/dialog-remove-table-row/DialogRemoveTableRow'
import TableCell from './components/TableCell'

export default {
  name: 'ControlTableWhiteList',
  components: {
    DialogEditWhitelist,
    DialogRemoveTableRow,
    TableFooter,
    TableBody,
    TableHeader,
    TableCell,
    TableEmpty,
    ControlTableWhitelistMobile,
  },
  data() {
    return {
      controlTableWhitelistFieldsDesktop,
      controlTableWhitelistDropdownMenuList,
      multipleSelectionIds: [],
      multipleSelectionNames: [],
      page: 1,
      perPage: 10,
      search: '',
      filteredPlateTruck: '',
    }
  },
  computed: {
    ...mapGetters({
      currentTerminal: GET_TERMINAL_CURRENT_ID,
      tableData: GET_WHITELIST_PAGE,
      pagination: GET_WHITELIST_PAGINATION,
      getIsLoading: GET_IS_LOADING_WHITELIST,
      isMobile: GET_IS_MOBILE,
      hasWhitelist: GET_HAS_WHITELIST,
    }),
    params() {
      return {
        plate_truck: this.filteredPlateTruck,
        page: this.page,
        'per-page': this.perPage,
      }
    },
  },
  watch: {
    currentTerminal() {
      if (this.hasWhitelist) {
        this.fetchWhiteList()
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.isSuperAdmin) {
        this.$router.push('/control')
      } else if (this.hasWhitelist) {
        this.perPage = this.isMobile ? 999 : 10
        this.fetchWhiteList()
      }
    })
  },
  methods: {
    ...mapActions({
      addWhitelist: ADD_WHITELIST,
      whitelistFetch: FETCH_WHITELIST_PAGE,
    }),
    fetchWhiteList() {
      this.whitelistFetch(this.params)
    },
    handleFilterInput(value) {
      this.search = value
    },
    handleClearFilter() {
      this.search = ''
      this.handleSearchFilter()
    },
    handleSearchFilter() {
      this.filteredPlateTruck = this.search
      this.fetchWhiteList()
    },
    changePage(page = 1) {
      this.page = page
      this.fetchWhiteList()
    },
    changeSize(size = 10) {
      this.perPage = size
      this.fetchWhiteList()
    },
    handleEdit(row) {
      this.setDialog({
        name: CONTROL_TABLE_WHITELIST_EDIT_ROW,
        visible: true,
        data: row,
      })
    },
    handleDelete({ id, plate_truck }) {
      this.setDialog({
        name: CONTROL_TABLE_WHITELIST_REMOVE_ROW,
        visible: true,
        data: {
          isMultipleDelete: false,
          id,
          plate_truck,
        },
      })
    },
    handleTableMenu(command) {
      if (command === controlTableRemoveSelectedItems) {
        this.setDialog({
          name: CONTROL_TABLE_WHITELIST_REMOVE_ROW,
          visible: true,
          data: {
            isMultipleDelete: true,
            idsList: this.multipleSelectionIds,
            namesList: this.multipleSelectionNames,
          },
        })
      } else if (command === controlTableAddItem) {
        this.addNewWhitelist()
      } else if (command === controlTableUpload) {
        this.$refs.csvUpload.click()
      }
    },
    handleSelectionChange({ ids, list }) {
      this.multipleSelectionIds = ids
      this.multipleSelectionNames = list.map(item => item.plate_truck)
    },
    addNewWhitelist() {
      this.filteredPlateTruck = ''
      this.setDialog({
        name: CONTROL_TABLE_WHITELIST_EDIT_ROW,
        visible: true,
      })
    },
    // обработка загрузки csv-файла
    fileSelected(event) {
      let file = event.target.files[0]

      const isCorrectType = file.type === 'text/csv'
      const isNormalSize = file.size / 1024 / 1024 < 10 // MAXIMUM_FILE_SIZE

      if (isCorrectType && isNormalSize) {
        let reader = new FileReader()

        reader.onload = e => {
          let whitelist = this.csvToObject(e.target.result)

          this.$refs.csvUpload.value = null

          if (whitelist) {
            const callback = () => {}
            const requestArray = {
              unload_id: this.currentTerminal,
              data: whitelist,
            }

            this.addWhitelist({ requestArray, callback, params: this.params })
          }
        }
        reader.onerror = () => showMessageError('Некорректный формат файла')
        reader.readAsText(file)
      } else {
        let message = isCorrectType
          ? 'Размер файла - не более 10 Мбайт'
          : 'Допустима загрузка только из файла формата .csv'

        this.$refs.csvUpload.value = null
        showMessageError(message)
      }
    },
    csvToObject(csv) {
      let lines = csv.split('\n')
      const emptyLine = lines[lines.length - 1].length ? 0 : 1

      // вначале определяем разделитель строк
      let i = 0
      let divider = ''

      do {
        if ([',', ';'].includes(lines[0][i++])) {
          divider = lines[0][i - 1]
        }
        if (i === lines[0].length - 1) {
          showMessageError(
            'Некорректный формат строк в файле - неполные или излишние данные',
          )

          return false
        }
      } while (!divider)

      // а потом бьём строки на столбцы и проверяем количесто столбцов
      const splited = lines.map(item => item.split(divider))
      let splitLines = splited.slice(0, splited.length - emptyLine)
      const errorLines = splitLines.filter(item => item.length !== 4)

      if (errorLines.length > 1) {
        showMessageError(
          'Некорректный формат строк в файле - неполные или излишние данные',
        )

        return false
      }
      let result = []

      for (let i = 1; i < splitLines.length; i++) {
        let currentline = splitLines[i]
        let errorText = ''

        // меняем возможные разделители в дате на точку
        currentline[1] = currentline[1].replace(/[-/]/g, '.')

        // валидация, причем вылетаем при первой же лшибке
        if (numAutoValidator(currentline[0])) {
          errorText = `Невозможно сохранить данные из файла. Номер авто в строке ${
            i + 1
          } не соответствует формату`
        } else if (!currentline[2]) {
          errorText = `Невозможно сохранить данные из файла.  Поле “Причина добавления“ в строке ${
            i + 1
          } не заполнено`
        } else if (!currentline[3] || currentline[3].length === 1) {
          errorText = `Невозможно сохранить данные из файла.  Поле “Кто добавил“ в строке ${
            i + 1
          } не заполнено`
        } else if (currentline[1].length && !checkCsvDate(currentline[1])) {
          errorText = `Невозможно сохранить данные из файла.  Дата в строке ${
            i + 1
          } не соответствует формату`
        } else {
          let duplicateTrucks = splitLines.filter(
            item => item[0] === currentline[0],
          )

          if (duplicateTrucks.length > 1) {
            errorText = `Невозможно сохранить данные из файла. Номер авто ${currentline[0]} дублируется в документе`
          }
        }
        if (errorText) {
          showMessageError(errorText)

          return false
        }

        result.push({
          plate_truck: currentline[0],
          white_until: currentline[1].length
            ? getBlacklistTerm(
                new Date(currentline[1].split('.').reverse().join('.')),
              )
            : '',
          reason: currentline[2],
          white_by: currentline[3],
        })
      }

      return result
    },
  },
}
</script>

<style lang="sass" scoped>
.control-table-exporters
  padding-top: 20px
  margin-top: 20px
.no-filtered
  display: flex
  justify-content: center
  align-items: center
  font-size: 24px
  height: 200px
  color: $main-font
.csv-upload
  width: 0
  height: 0
  visibility: hidden
</style>
