<script>
import { DATE_FORMAT_FULL_RU } from '@/constants/date'
import { blacklistDateBrief, getFormattedTzDate } from '@/core'

export default {
  name: 'TableCell',
  props: {
    item: {
      type: [String, Array, Number, Boolean],
      default: '',
    },
    row: {
      type: Object,
      default: () => {},
    },
    propKey: {
      type: String,
      default: '',
    },
    status: {
      type: [String, Number, Boolean],
      default: '',
    },
  },
  methods: {
    createFullDate(createElement, key) {
      const date = this.row[key]

      return [
        createElement('div', getFormattedTzDate(date, DATE_FORMAT_FULL_RU)),
      ]
    },
    createBriefDate(createElement, key) {
      const date = this.row[key]

      return [
        date
          ? createElement('div', `До ${blacklistDateBrief(date)}`)
          : 'Бессрочно',
      ]
    },
    createPlateTruck(createElement, key) {
      const date = this.row[key]

      return [
        createElement(
          'div',
          { style: { fontSize: '18px', fontWeight: 'bold' } },
          date,
        ),
      ]
    },
  },
  render(createElement) {
    let classNames = [this.propKey]
    let renderData = [this.item]

    switch (this.propKey) {
      case 'created_at':
      case 'updated_at':
        renderData = this.createFullDate(createElement, this.propKey)
        break

      case 'white_until':
        renderData = this.createBriefDate(createElement, this.propKey)
        break

      case 'plate_truck':
        renderData = this.createPlateTruck(createElement, this.propKey)
        break
    }

    return createElement('div', { class: classNames }, renderData)
  },
}
</script>
